/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-18 09:50:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-25 19:40:40
 */
export const columns = [
  {
    title: '经销商',
    dataIndex: 'cusName',
    key: 'cusName',
    align: 'center',
    ellipsis: true,
    width: 200,
    fixed: 'left',
    scopedSlots: {customRender: 'cusName'}
  },
  {
    title: '商品名称',
    dataIndex: 'productName',
    key: 'productName',
    width: 180,
    align: 'center',
    ellipsis: true,
    scopedSlots: { customRender: 'productName'}
  },
  {
    title: '商品图片',
    dataIndex: 'productImage',
    key: 'productImage',
    /*width:150,*/
    width: 120,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'productImage'}
  },
  {
    title: '经销商编码名称',
    dataIndex: 'cusCodeName',
    key: 'cusCodeName',
    /*width:150,*/
    width: 180,
    align: 'center',
    ellipsis: true,
  },
  {
    title: 'CNC商号',
    dataIndex: 'cncCompany',
    key: 'cncCompany',
    /*width:150,*/
    width: 120,
    align:'center',
    ellipsis:true
  },
  {
      title: '规格型号',
      dataIndex: 'specStr',
      key: 'specStr',
      /*width:150,*/
      width: 120,
      align:'center',
      ellipsis:true
  },
  {
      title: '总成号',
      dataIndex: 'assemblyNumber',
      key: 'assemblyNumber',
      /*width:150,*/
      width: 120,
      align:'center',
      ellipsis:true
  },
  {
    title: '参考价格',
    dataIndex: 'goodPrice',
    key: 'goodPrice',
    /*width:150,*/
    width: 100,
    align:'center',
    ellipsis:true
  },
  {
    title: '商品价格',
    dataIndex: 'productPrice',
    key: 'productPrice',
    /*width:150,*/
    width: 100,
    align: 'right',
    ellipsis: true,
    scopedSlots: {customRender: 'productPrice'}
  },
  {
    title: '状态',
    dataIndex: 'flowStatus',
    key: 'flowStatus',
    /*width:150,*/
    width: 120,
    align: 'center',
    ellipsis: true,
    scopedSlots: {customRender: 'flowStatus'}
  },
  {
    title: '操作时间',
    dataIndex: 'modifyTime',
    key: 'remark',
    width:150,
    align: 'center',
    ellipsis: true
  },

  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   key: 'action',
  //   width: 150,
  //   align: 'center',
  //   ellipsis: true,
  //   scopedSlots: {customRender: 'action'}
  // }
]
