<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-19 11:50:17
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-26 14:17:15
-->
<template>
  <div class="">
    <a-modal
      title="修改商品信息"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      class="customer-table-modal"
      width="30%"
      :maskClosable="false"
      >
        <a-form-model ref="form" :model="form" layout="horizontal" :label-col="{span:5}" :wrapper-col="{span:19}">
          <a-form-model-item label="经销商编码名称">
            <a-input v-model="form.cusCodeName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品价格">
            <a-input v-model="form.productPrice"></a-input>
          </a-form-model-item>
        </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { editCustomerPricePolicy, selectByIdCustomerPricePolicy, addCustomerPricePolicy } from '../api/CustomerPricePolicyApi'
  export default {
    data() {
      return {
        visible: false,
        form: {
          productPrice: '',
          handle: '',
          id: '',
          cusCodeName: ''
        }
      }
    },
    methods: {
      isShow(row, item) {
        this.axios.get('/api/customer/customer/customerPricePolicy/selectById/' + row.id).then(res => {
          this.form.productPrice = res.body.productPrice
          this.form.cusCodeName = res.body.cusCodeName
          this.form.id = res.body.id
        })
        this.visible = true;
        this.form.handle = item
      },
      handleCancel(e) {
        this.visible = false;
      },
      handleOk(e) {
        this.$refs.form.validate(async valid => {
          if (!valid) {
              return
          }
          this.axios.post('/api/customer/customer/customerPricePolicy/edit', {id: this.form.id, productPrice: this.form.productPrice, cusCodeName: this.form.cusCodeName}).then(res => {
            if (res.code === 200) {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.form = {}
              this.visible = false
          } else {
              this.$notification.error({ message: res.message })
          }
          })
          // const res = this.form.handle === 'add' ? await addCustomerPricePolicy(this.form.productPrice) : await editCustomerPricePolicy(this.form.productPrice)
          
        })
      },
    }
  }
</script>

<style lang="scss">
  
</style>
