<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-18 09:50:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-05 16:25:01
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="form" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="经销商名称" prop="cusName" :autoLink="false" ref="test">
                <a-select placeholder="请选择" v-model="selete" label-in-value @change="handleChange">
                    <a-select-option :value="item.id" v-for="(item, index) in clientList" :key="index">{{item.fname + "(" + item.fshortName + ")"}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item label="备注" prop="remark">
                <a-input v-model="form.remark" placeholder="备注"></a-input>
            </a-form-model-item> -->
            <a-form-model-item label="新增商品">
                <a-button type="primary" @click="onProductChooseModal()">新增商品</a-button>
            </a-form-model-item>
            <div class="table-outer">
                <div v-if="dataList.length > 0">
                    <a-table :columns="columns" :data-source="dataList" bordered>
                        <span slot="action" slot-scope="item, row">
                            <a @click="ondelete(row.id)">移除</a>
                        </span>
                        <span slot="produnctPrice" slot-scope="item, row">
                            <a-input-number v-model="row.productPrice"></a-input-number>
                        </span>
                        <span slot="cusCodeName" slot-scope="item, row">
                            <a-input v-model="row.cusCodeName"></a-input>
                        </span>
                    </a-table>
                </div>
            </div>
        </a-form-model>
        <CustomerTable ref="CustomerTable" :cusId="form.cusId" @get-pruduct-list="getProductList"></CustomerTable>
    </a-modal>
</template>

<script>
import CustomerTable from './customerTable'
import { editCustomerPricePolicy, selectByIdCustomerPricePolicy, addCustomerPricePolicy } from '../api/CustomerPricePolicyApi'
export default {
    components: {
        CustomerTable
    },
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            dataList: [],
            clientList: [],
            form: {
                cusName: '',
                remark: '',
                cusId: '0',
            },
            selete: [],
            columns: [
                {
                    title: '商品名称',
                    dataIndex: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: '经销商编码名称',
                    dataIndex: 'cusCodeName',
                    scopedSlots: { customRender: 'cusCodeName' },
                },
                {
                    title: '所属分类',
                    dataIndex: 'categoryName',
                },
                {
                    title: '市场价',
                    dataIndex: 'price',
                    scopedSlots: { customRender: 'price' },
                },
                {
                    title: '价格',
                    dataIndex: 'produnctPrice',
                    scopedSlots: { customRender: 'produnctPrice' },
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                }
            ],
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            const that = this
            this.rowData = {}
            this.dataList = []
            this.selete = []
            this.axios.get('/api/customer/customer/customerInfo/allList?status=true').then(res => {
                this.clientList = res.body
            })
            if(handle == 'edit') {
                this.axios.get('/api/customer/customer/customerPricePolicy/selectById/' + row.id).then(res => {
                    that.selete = [
                        {
                            key:res.body.cusId,
                            label: res.body.cusName
                        }
                    ]
                    that.dataList = res.body
                }) 
            }
        },
        onProductChooseModal() {
            const _this = this
            _this.$refs.CustomerTable.isShow()
        },
        // 删除商品
        ondelete(id) {
            this.dataList.splice(this.dataList.findIndex(e => {return e.id == id}), 1)
        },
        getProductList(data) {
            const arr = new Map()
            let list = this.dataList.concat(data)
            let item = []
            list.forEach(e => {
                item.push(
                    {
                        cusCodeName: e.name,
                        productPrice: e.price,
                        id: e.id,
                        name: e.name,
                        price: e.price,
                        categoryName: e.categoryName,
                        imgUrl: e.imgUrl,
                    }
                )
            })
            this.dataList = item.filter(e => !arr.has(e.id) && arr.set(e.id, 1))
        },
        handleChange(value) {
            this.$refs.test.onFieldChange()
            this.form.cusName = value.label
            this.form.cusId = value.key
        },
        /**
         * 表单提交
         */
        toSubmit() {
            
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                // let obj = []
                // this.dataList.map((item, index) => {
                //     obj.push(Object.assign({}, item, {cusName: this.form.cusName, remark: this.form.remark, cusId: this.form.cusId}))
                // })
                const list = []
                this.dataList.forEach(item => {
                    list.push({
                        productId: item.id,
                        productImage: item.imgUrl,
                        productName: item.name,
                        productPrice: item.productPrice,
                        // remark: this.form.remark,
                        cusId: this.form.cusId,
                        cusName: this.form.cusName,
                        cusCodeName: item.cusCodeName
                    });
                });
                this.rowData = list
                const res = this.handle === 'add' ? await addCustomerPricePolicy(this.rowData) : await editCustomerPricePolicy(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.form = {}
                    this.visible = false
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {
        
    },

}
</script>

<style lang="less" scoped>

</style>
