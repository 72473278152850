<template>
  <a-modal
    :title="rowData.fname+'('+rowData.cusCode+')'"
    style="top: 8px"
    :width="1200"
    v-model="visible"
    :footer="null"
    @cancel='handleCancel'
    :maskClosable="false"
  >
    <a-form-model ref="form" :model="rowData" :label-col="{span:6}" :wrapper-col="{span:18}">
      <a-tabs default-active-key="1" size="small" type="card">
        <a-tab-pane key="1" tab="经销商信息" force-render>
          <div>
            <a-divider orientation="left">基础信息</a-divider>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="经销商名称">
                  <a-form-item class="info-item">{{ rowData.fname }}</a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="经销商简称">
                  <a-form-item class="info-item">{{ rowData.fshortName }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="账号">
                  <a-form-item class="info-item">{{ rowData.account }}</a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="编码" prop="cusCode">
                  <a-form-item class="info-item">{{ rowData.cusCode == null ? '未设置' : rowData.cusCode }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="曾用名">
                  <a-form-item class="info-item">{{ rowData.usedName == null ? '无' : rowData.usedName }}</a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="经销商标签">
                  <a-tag v-for="(item, index) in rowData.cusTagNameList" :key="index"> {{item}}</a-tag>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="联系人" prop="contactPerson">
                  <a-form-item class="info-item">{{ rowData.contactPerson == null ? '无' : rowData.contactPerson }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="联系方式" prop="mobile">
                  <a-form-item class="info-item">{{ rowData.mobile == null ? '无' : rowData.mobile }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="备用联系人" prop="alternateContact">
                  <a-form-item class="info-item">
                    {{ rowData.alternateContact == null ? '无' : rowData.alternateContact }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="备用联系方式" prop="alternateMobile">
                  <a-form-item class="info-item">{{ rowData.alternateMobile == null ? '无' : rowData.alternateMobile }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :md="12">
                <a-form-model-item label="经销商区域" prop="receiptInvoice">
                  <a-form-item class="info-item">{{ rowData.province }}/{{ rowData.city }}/{{ rowData.county }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="详细地址" prop="address">
                  <a-form-item class="info-item">{{ rowData.address }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <a-divider orientation="left">开票相关</a-divider>
          <div>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="邮箱" prop="email">
                  <a-form-item class="info-item">{{ rowData.email == null ? '无' : rowData.email }}</a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="银行账号" prop="bankAccount">
                  <a-form-item class="info-item">{{ rowData.bankAccount == null ? '无' : rowData.bankAccount }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="发票抬头" prop="receiptInvoice">
                  <a-form-item class="info-item">{{ rowData.receiptInvoice == null ? '无' : rowData.receiptInvoice }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="纳税识别号" prop="receiptNumber">
                  <a-form-item class="info-item">{{ rowData.receiptNumber == null ? '无' : rowData.receiptNumber }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="增值税率" prop="vatRate">
                  <a-form-item class="info-item">{{ rowData.vatRate == null ? '未设置' : rowData.vatRate + '%' }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <a-divider orientation="left">其他</a-divider>
          <div>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="合同有效期" prop="contractValidity">
                  <a-form-item class="info-item">
                    {{ rowData.contractValidity == null ? '无' : rowData.contractValidity }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="保证金" prop="deposit">
                  <a-form-item class="info-item">{{ rowData.deposit == null ? '未缴纳' : rowData.deposit }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="销售模式" prop="salesModel" :autoLink="false" ref="test">
                  <a-form-item class="info-item">内销</a-form-item>
                </a-form-model-item>
              </a-col>
              <a-col :md="12">
                <a-form-model-item label="折扣政策" prop="rebatePolicy">
                  <a-form-item class="info-item">{{ rowData.rebatePolicy == null ? '无' : rowData.rebatePolicy }}
                  </a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :md="12">
                <a-form-model-item label="状态" prop="status">
                  <a-form-item class="info-item">{{ rowData.status ? '正常' : '锁定' }}</a-form-item>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-form-model-item label="备注说明" prop="remark" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
                <a-textarea class="info-item" :disabled="true" v-model="rowData.remark"></a-textarea>
              </a-form-model-item>
            </a-row>
          </div>

        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="收货地址" force-render>
          <a-empty v-if="cusAddressData.length <= 0 "></a-empty>
          <a-card class="enzo-address-card" v-if="cusAddressData.length > 0 " :key="index"  v-for="(item, index) in cusAddressData" >
            <a-row>
              <a-descriptions :column="3" size="small">
                <a-descriptions-item label="所属区域">{{item.province}}/ {{item.city}}/ {{item.county}}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions :column="3" size="small">
                <a-descriptions-item label="详细地址"> {{item.address}}</a-descriptions-item>
              </a-descriptions>
            </a-row>
            <div class="footer-bts">
              <a @click="deleteAddress(item.id)">删除</a>
            </div>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="3" tab="资金流水" force-render>
          <div class="tag-list">
            <a-table :columns="columns" @change="changeTable" :data-source="dataList" :pagination="page" bordered
                     :rowKey="(record) => record.id">
            <span slot="amountType" slot-scope="text">
              <a-tag color="#87d068" v-if="text == 1">收入</a-tag>
              <a-tag color="#f50" v-if="text == 2">支出</a-tag>
            </span>
                  <span slot-scope="text" slot="dealAmount">{{ text.toFixed(2) }}</span>
                  <span slot-scope="text" slot="balance">{{ text.toFixed(2) }}</span>
                  <span slot-scope="text" slot="customerAmount">{{ text.toFixed(2) }}</span>
                  <span slot="dealType" slot-scope="text">
              <a-tag color="" v-if="text == 1">支付</a-tag>
              <a-tag color="" v-if="text == 2">提现</a-tag>
              <a-tag color="" v-if="text == 3">充值</a-tag>
              <a-tag color="" v-if="text == 4">退款</a-tag>
              <a-tag color="" v-if="text == 5">订单结算</a-tag>
              <a-tag color="" v-if="text == 6">订单取消</a-tag>
              <a-tag color="" v-if="text == 7">体现取消/驳回</a-tag>
              <a-tag color="" v-if="text == 8">订单退款</a-tag>
              <a-tag color="" v-if="text == 9">平台操作</a-tag>
            </span>
            </a-table>
          </div>
        </a-tab-pane> -->
      </a-tabs>
    </a-form-model>

    <div class="footer-bts">
      <a-button key="back" @click="handleCancel">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      cusAddressData: {},
      loading: false,
      status: true,
      dataList: [],
      columns: [
        {
          title: '金额类型',
          dataIndex: 'amountType',
          align:'center',
          key: 'amountType',
          scopedSlots: { customRender: 'amountType' },
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align:'center',
          key: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '余额',
          dataIndex: 'customerAmount',
          key: 'customerAmount',
          align:'right',
          scopedSlots: { customRender: 'customerAmount' }
        },
        {
          title: '交易金额',
          dataIndex: 'dealAmount',
          align:'right',
          key: 'dealAmount',
          scopedSlots: { customRender: 'dealAmount' },
        },
        {
          title: '交易后金额',
          key: 'balance',
          align:'right',
          dataIndex: 'balance',
          scopedSlots: { customRender: 'balance' }
        },
        {
          title: '交易时间',
          align:'center',
          key: 'dealDate',
          dataIndex: 'dealDate',
        }
      ],
      page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      //客户信息
      this.rowData = {}
      //客户地址
      this.cusAddressData = {}
      this.dataList = []
      this.axios.get('/api/customer/customer/customerInfo/selectById/' + row.cusId).then((res) => {
        this.rowData = res.body
      })

      this.getData(row.id);
      this.getAddressData(row.id);
    },
    // 关闭弹框
    handleCancel() {
      this.rowData = {}
      this.dataList = []
      this.visible = false
    },

    getData(cusId) {
      this.axios.get('/api/customer/customer/customerPayRecord/list', {params: {
          pageNumber: this.page.current,
          pageSize: this.page.pageSize,
          customerId: cusId
        }}).then(res => {
          const { total, records } = res.body
          this.dataList = records
          this.page.total = total
      })
    },

    //获取客户地址
    getAddressData(cusId) {
      this.axios.get('/api/customer/customer/customerInfoAddress/selectByCusId/'+cusId).then(res => {
          this.cusAddressData = res.body
      })
    },

    //删除地址
    deleteAddress(addressId){
      const _this = this;
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          _this.axios.get('/api/customer/customer/customerInfoAddress/del/'+addressId).then(res => {
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getAddressData(_this.rowData.id);
              } else {
                _this.$notification.error({ message: res.message })
              }
          })
        },
        onCancel() {},
      })
    },

    handleChange(value) {
      this.$refs.test.onFieldChange()
      console.log(value)
    },

    onChange(value) {
      console.log(value)
    },

    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

  },
  created() {

  },
}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.enzo-address-card{
  margin-bottom: 10px;
}
.info-item {
  background-color: #f4f4f4;
  padding-left: 10px;
  border-radius: 3px;
  min-height: 40px;
}

.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
