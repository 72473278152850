/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-18 09:50:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-29 10:03:33
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listCustomerPricePolicy = params => axios({
    url: '/api/customer/customer/customerPricePolicy/customerList',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addCustomerPricePolicy = params => axios({
    url: '/api/customer/customer/customerPricePolicy/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editCustomerPricePolicy = params => axios({
    url: '/api/customer/customer/customerPricePolicy/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delCustomerPricePolicy = params => axios({
    url:'/api/customer/customer/customerPricePolicy/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdCustomerPricePolicy = params => axios({
    url: '/api/customer/system/customerPricePolicy/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
